import { clipInLeftAnimation } from "@/utils/animations";
import { Link } from "gatsby";
import React, { FC, useRef } from "react";

interface BreadCrumbProps {
  title: string;
  routingPaths: { path: string; routeName: string }[];
  activePath: { routeName: string };
}

export const BreadCrumb: FC<BreadCrumbProps> = ({ title, routingPaths, activePath }) => {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <section className="breadcrumbs-custom m-0">
      <div className="breadcrumbs-custom-main bg-default pt-5 p-0" ref={ref}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-9">
              <h1
                className="text-white heading breadcrumb-title-head pb-5"
                style={clipInLeftAnimation(1, 0.3, ref)}
              >
                <span className="text-white">{title}</span>
              </h1>
            </div>
          </div>
        </div>
        <div className="breadcrumbs-custom-aside">
          <ul className="breadcrumbs-custom-path">
            {routingPaths.map(({ path, routeName }, index) => {
              return (
                <li key={index}>
                  <Link to={path}>{routeName}</Link>
                </li>
              );
            })}
            <li className="breadcrumb-active">{activePath.routeName}</li>
          </ul>
        </div>
      </div>
    </section>
  );
};
