import { RedirectButton } from "@/components/Shared/RedirectButton";
import React, { FC } from "react";
import { LazyImage } from "../LazyImage";
import { PhoneFrame } from "../PhoneFrame";

interface ContentUiContextProps {
  title?: string;
  shortDescription?: string;
  quote?: string;
  contentList?: string[];
  imageURL: string;
  contextURL: string;
  imageDirection: "left" | "right";
  buttons?: { path: string; colour: "Aqua" | "Blue"; title: string; id: string }[];
}

export const ContentUiContext: FC<ContentUiContextProps> = ({
  shortDescription,
  title,
  contentList,
  quote,
  imageURL,
  contextURL,
  imageDirection,
  buttons,
}) => {
  return (
    <div className="container">
      <div
        className={`row align-items-center ${imageDirection === "left" ? "flex-row-reverse" : ""}`}
      >
        <div className="col-lg-6 col-md-6">
          <div className="unit unit-spacing-1 align-items-center">
            <div className="unit-left">
              <div className="icon">
                <LazyImage alt="icon" height={64} width={64} src={contextURL} />
              </div>
            </div>
            <div className="unit-body">
              <h3>{title}</h3>
            </div>
          </div>
          <div className="block-4 offset-top-4">
            <p className="big text-dark">{shortDescription}</p>

            {quote && <BlockQuote quote={quote} />}
            {contentList && <ContentList list={contentList} />}
            <div className="group-buttons-responsive group-middle">
              {buttons.map(({ colour, path, title, id }) => {
                return <RedirectButton path={path} title={title} type={colour} key={id} />;
              })}
            </div>
          </div>
        </div>
        <div className="col-lg-6 col-md-6 pb-2">
          <div className="item">
            <PhoneFrame imageURL={imageURL} />
          </div>
          <div className={`box-custom-4-main-decoration ${imageDirection}`}></div>
        </div>
      </div>
    </div>
  );
};

const BlockQuote: FC<{ quote: string }> = ({ quote }) => {
  return (
    <blockquote className="quote-light">
      <div className="quote-light-mark linearicons-quote-open" />
      <div className="quote-light-text">
        <p>{quote}</p>
      </div>
    </blockquote>
  );
};

const ContentList: FC<{ list: string[] }> = ({ list }) => {
  return (
    <ul className="list-marked list-marked_secondary">
      {list.map((each, index) => {
        return <li key={index}>{each}</li>;
      })}
    </ul>
  );
};
