import Layout from "@/components/Layout";
import SEO, { SEOType } from "@/components/SEO";
import { BreadCrumb } from "@/components/Shared/BreadCrumb";
import { EmbeddedAssetBlockImageUi } from "@/components/Shared/RichtextUi/EmbeddedAsset";
import { EmbeddedBlockUi } from "@/components/Shared/RichtextUi/EmbeddedBlockUi";
import {
  HeadingFive,
  HeadingFour,
  HeadingOne,
  HeadingSix,
  HeadingThree,
  HeadingTwo,
} from "@/components/Shared/RichtextUi/Headings";
import { ParagraphWide } from "@/components/Shared/RichtextUi/ParagraphWide";
import {
  StructuredDataSnippet,
  StructuredDataSnippetTag,
} from "@/components/Shared/StructuredDataSnippetTag";
import { documentToReactComponents, Options } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql, PageProps } from "gatsby";
import React, { Component } from "react";

interface ServicesPageProps extends PageProps {
  data: {
    contentfulServiceRef: {
      serviceCard: { featureTitle: string };
      detailedPage: {
        seo: SEOType;
        content: {
          raw: any;
          references: any[];
        };
        structuredDataSnippets?: StructuredDataSnippet[];
      };
    };
  };
}

export const query = graphql`
  query ($slug: String!) {
    contentfulServiceRef(slug: { eq: $slug }) {
      contentful_id
      serviceCard {
        featureTitle
      }
      detailedPage {
        seo {
          metaTitle
          documentTitle
          metaTitle
          metaUrl
          metaAuthor
          metaKeywords
          description {
            description
          }
        }
        content {
          raw
          references {
            ... on ContentfulTitleDescriptionAndPointsRtAssetRef {
              __typename
              contentful_id
              title
              description {
                description
              }
              points
              pointsType
            }
            ... on ContentfulTitleWithDescriptionRtEmbeddedAssetRef {
              __typename
              title
              description {
                description
              }
              contentful_id
            }
            ... on ContentfulServiceRef {
              __typename
              contentful_id
              slug
              serviceCard {
                uiPlacement
                featureTitle
                featureShortDescription
                featuresList
                featureIcon {
                  file {
                    url
                  }
                }
                featureImage {
                  file {
                    url
                  }
                }
                redirectButtons {
                  id
                  path
                  buttonName
                  buttonColor
                }
              }
            }
            ... on ContentfulFaQsListForRte {
              __typename
              contentful_id
              faQs {
                id
                question
                answer {
                  answer
                }
              }
            }
          }
        }

        structuredDataSnippets {
          snippet {
            id
            snippet
          }
        }
      }
    }
  }
`;

export default class ServiceDetail extends Component<ServicesPageProps> {
  private provideOptions(): Options {
    const options: Options = {
      renderNode: {
        [BLOCKS.DOCUMENT]: (node, children) => (
          <div className="container grid-demo grid-demo-underlined">{children}</div>
        ),
        [BLOCKS.PARAGRAPH]: (_, children) => <ParagraphWide paragraph={children} />,
        [BLOCKS.HEADING_1]: (_, children) => <HeadingOne heading={children} />,
        [BLOCKS.HEADING_2]: (_, children) => <HeadingTwo heading={children} />,
        [BLOCKS.HEADING_3]: (_, children) => <HeadingThree heading={children} />,
        [BLOCKS.HEADING_4]: (_, children) => <HeadingFour heading={children} />,
        [BLOCKS.HEADING_5]: (_, children) => <HeadingFive heading={children} />,
        [BLOCKS.HEADING_6]: (_, children) => <HeadingSix heading={children} />,
        "embedded-entry-block": (node) => (
          <EmbeddedBlockUi
            node={node}
            references={this.props.data.contentfulServiceRef.detailedPage.content.references}
          />
        ),
        "embedded-asset-block": (node) => (
          <EmbeddedAssetBlockImageUi
            node={node}
            references={this.props.data.contentfulServiceRef.detailedPage.content.references}
          />
        ),
      },
    };
    return options;
  }

  render(): JSX.Element {
    const {
      description: { description },
      documentTitle,
      metaKeywords,
      metaTitle,
      metaUrl,
      metaAuthor,
    } = this.props.data.contentfulServiceRef.detailedPage.seo;

    return (
      <Layout>
        <StructuredDataSnippetTag
          snippets={this.props.data.contentfulServiceRef.detailedPage.structuredDataSnippets}
        />
        <SEO
          contentfulSeo={{
            documentTitle,
            metaTitle,
            metaDescription: description,
            metaKeywords,
            metaUrl,
            metaAuthor,
          }}
        />
        <BreadCrumb
          activePath={{ routeName: this.props.data.contentfulServiceRef.serviceCard.featureTitle }}
          routingPaths={[
            { path: "/", routeName: "Home" },
            { path: "/services/", routeName: "Services" },
          ]}
          title={this.props.data.contentfulServiceRef.serviceCard.featureTitle}
        />
        <section className="section section-md pt-2 pb-0 pl-0 pr-0">
          <div>
            {documentToReactComponents(
              JSON.parse(this.props.data.contentfulServiceRef.detailedPage.content.raw),
              this.provideOptions(),
            )}
          </div>
        </section>
      </Layout>
    );
  }
}
