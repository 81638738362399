import React, { FC } from "react";

interface TitleWithDescriptionProps {
  title: string;
  description: string;
}

export const TitleWithDescription: FC<TitleWithDescriptionProps> = ({ title, description }) => {
  return (
    <div className="container mt-0">
      <div className="row pl-3 pt-1 title-desc-points">
        <div className="col-12 p-0 m-0 mt-3">
          <h4>{title}</h4>
          <p className="mt-1 white-space-text pl-2 text-dark">{description}</p>
        </div>
      </div>
    </div>
  );
};
