import { Link } from "gatsby";
import React, { FC } from "react";

interface RedirectButtonProps {
  type: "Blue" | "Aqua";
  path: string;
  title: string;
}

export const RedirectButton: FC<RedirectButtonProps> = ({ type, path, title }) => {
  function ExternalRedirect(): JSX.Element {
    return (
      <a
        className={`button button-winona ${
          type === "Aqua" ? "button-secondary" : "button-default-outline"
        }`}
        href={path}
      >
        {title}
      </a>
    );
  }

  function InternalRedirect(): JSX.Element {
    return (
      <Link
        className={`button button-winona ${
          type === "Aqua" ? "button-secondary" : "button-default-outline"
        }`}
        to={path}
      >
        {title}
      </Link>
    );
  }

  return path?.includes("http") ? <ExternalRedirect /> : <InternalRedirect />;
};
