import React, { FC } from "react";
import { ListProps } from "./UnorderedList";

export const OrderedList: FC<ListProps> = ({ lists }) => {
  return (
    <ul className="list-ordered text-dark">
      {lists.map((each, index) => {
        return <li key={index}>{each}</li>;
      })}
    </ul>
  );
};
