import { IndividualItemViewFromList } from "@/components/Helpers/IndividualItemViewFromList";
import { ContentUiContext } from "@/components/Helpers/UiImageAndContentTab";
import { Block, Inline } from "@contentful/rich-text-types";
import React, { FC } from "react";
import { TitleDescriptionAndPointsBelow } from "./TitleDescriptionAndPointsBelow";
import { TitleWithDescription } from "./TitleWithDescription";

interface EmbeddedBlockUiProps {
  node: Block | Inline;
  references: any[];
}

export const EmbeddedBlockUi: FC<EmbeddedBlockUiProps> = ({ node, references }) => {
  const assetId = node?.data?.target?.sys?.id;

  if (!assetId) return null;

  const entryAssetDocument = references?.find((each) => each?.contentful_id === assetId) || null;

  if (!entryAssetDocument) {
    return null;
  }

  if (entryAssetDocument?.__typename === "ContentfulTitleDescriptionAndPointsRtAssetRef") {
    return (
      <TitleDescriptionAndPointsBelow
        title={entryAssetDocument?.title}
        description={entryAssetDocument?.description?.description}
        lists={entryAssetDocument?.points}
        type={entryAssetDocument?.pointsType}
      />
    );
  }

  if (entryAssetDocument?.__typename === "ContentfulTitleWithDescriptionRtEmbeddedAssetRef") {
    return (
      <TitleWithDescription
        title={entryAssetDocument?.title}
        description={entryAssetDocument?.description?.description}
      />
    );
  }

  if (entryAssetDocument?.__typename === "ContentfulServiceRef") {
    const {
      serviceCard: {
        uiPlacement,
        featureTitle,
        featureShortDescription,
        quote,
        featuresList,
        featureImage: {
          file: { url: featureImage },
        },
        featureIcon: {
          file: { url: featureIcon },
        },
        redirectButtons,
      },
    } = entryAssetDocument;

    let redirectsBtns = [];
    if (redirectButtons) {
      redirectsBtns = redirectButtons?.map(({ id, buttonName, buttonColor, path }) => {
        return {
          path,
          colour: buttonColor,
          title: buttonName,
          id,
        };
      });
    }

    return (
      <ContentUiContext
        title={featureTitle}
        shortDescription={featureShortDescription}
        imageURL={featureImage}
        quote={quote}
        imageDirection={uiPlacement[0] === "Right Image and Left Content" ? "right" : "left"}
        contextURL={featureIcon}
        contentList={featuresList}
        buttons={redirectsBtns}
      />
    );
  }

  if (entryAssetDocument?.__typename === "ContentfulFaQsListForRte") {
    const { faQs } = entryAssetDocument;
    const list: { title: string; description: string }[] = faQs.map(
      ({ question, answer: { answer } }) => {
        return {
          title: question,
          description: answer,
        };
      },
    );

    return (
      <section className="section text-center mt-4">
        <div className="container">
          <h3 className="offset-top-7">Frequently Asked Questions</h3>

          <IndividualItemViewFromList lists={list} />
        </div>
      </section>
    );
  }
  return <p>view missing</p>;
};
