import { LazyImage } from "@/components/Helpers/LazyImage";
import React from "react";

interface PhotoFrameProps {
  imageURL: string;
  phoneHeight?: number;
  phoneWidth?: number;
}

export const PhoneFrame: React.FC<PhotoFrameProps> = ({
  imageURL,
  phoneHeight = 509.16,
  phoneWidth = 235,
}) => {
  return (
    <div className="iphone-x banner-home-slider" draggable={false}>
      <i></i>

      <LazyImage height={phoneHeight} width={phoneWidth} alt="banner-ad1" src={imageURL} />
    </div>
  );
};
