import React, { FC } from "react";

export interface ListProps {
  lists: string[];
}

export const UnorderedList: FC<ListProps> = ({ lists }) => {
  return (
    <ul className="list-marked">
      {lists.map((each, index) => {
        return <li key={index}>{each}</li>;
      })}
    </ul>
  );
};
