import React, { FC, MouseEvent } from "react";

interface ViewItemProps {
  isActive: boolean;
  listItem: ListItem;
  setActiveView: React.Dispatch<React.SetStateAction<number | null>>;
  activeView: number;
  currentIndex: number;
}

export interface ListItem {
  title: string;
  description: string;
}

export const ViewItem: FC<ViewItemProps> = ({
  isActive,
  listItem: { title, description },
  setActiveView,
  currentIndex,
  activeView,
}) => {
  function preventDefaultHandle(ev: MouseEvent) {
    ev.preventDefault();
  }

  function handleActiveState() {
    if (currentIndex === activeView) {
      setActiveView(null);
    } else {
      setActiveView(currentIndex);
    }
  }

  return (
    <div className="col-lg-6  col-md-8 mb-4" onClick={handleActiveState}>
      <article className={`card card-custom card-classic ${isActive && "active"}`}>
        <div className="card-header" id="app-accordion-heading-4" role="tab">
          <div className="card-title">
            <a
              className={`card-link ${!isActive && "collapsed"}`}
              role="button"
              href="."
              onClick={preventDefaultHandle}
              aria-controls="app-accordion-collapse-4"
              aria-expanded="true"
            >
              {title}
              <div className="card-arrow" />
            </a>
          </div>
        </div>
        <div
          className={`collapse ${isActive && "show"}`}
          id="app-accordion-collapse-4"
          role="tabpanel"
          aria-labelledby="app-accordion-heading-4"
          data-parent="#app-accordion"
        >
          <div className="card-body">
            <p>{description}</p>
          </div>
        </div>
      </article>
    </div>
  );
};
