import React, { FC } from "react";
import { OrderedList } from "./OrderedList";
import { UnorderedList } from "./UnorderedList";

interface TitleDescriptionAndPointsBelowProps {
  title: string;
  description: string;
  lists: string[];
  type: string;
}

export const TitleDescriptionAndPointsBelow: FC<TitleDescriptionAndPointsBelowProps> = ({
  title,
  description,
  lists,
  type,
}) => {
  return (
    <div className="container mt-0">
      <div className="row pl-3 pt-1 title-desc-points">
        <div className="col-12 p-0 m-0 mt-3">
          <h4>{title}</h4>
          <p className="mt-1 white-space-text text-dark">{description}</p>
        </div>
      </div>
      {type === "Bullet Points" ? <UnorderedList lists={lists} /> : <OrderedList lists={lists} />}
    </div>
  );
};
