import React, { FC, useState } from "react";
import { ListItem, ViewItem } from "./Item";

interface IndividualItemViewFromListProps {
  lists: ListItem[];
}

export const IndividualItemViewFromList: FC<IndividualItemViewFromListProps> = ({ lists }) => {
  const [activeView, setActiveView] = useState<number | null>(null);

  return (
    <div
      className="card-group-custom card-group-classic card-group-classic_1 row justify-content-center justify-content-lg-start "
      role="tablist"
      id="app-accordion"
      aria-multiselectable="false"
    >
      {lists.map(({ title, description }, index) => {
        return (
          <ViewItem
            activeView={activeView}
            currentIndex={index}
            setActiveView={setActiveView}
            isActive={activeView === index}
            listItem={{ title, description }}
            key={index}
          />
        );
      })}
    </div>
  );
};
