import React, { FC } from "react";
import { Helmet } from "react-helmet";

export interface StructuredDataSnippet {
  snippet: { id: string; snippet: string };
}

interface StructuredDataSnippetTagProps {
  snippets: StructuredDataSnippet[];
}

export const StructuredDataSnippetTag: FC<StructuredDataSnippetTagProps> = ({ snippets }) => {
  return (
    <React.Fragment>
      {snippets?.length && (
        <Helmet>
          {snippets.map(({ snippet: { snippet } }, idx) => {
            return (
              <script type="application/ld+json" key={idx}>
                {Object.freeze(snippet)}
              </script>
            );
          })}
        </Helmet>
      )}
    </React.Fragment>
  );
};
